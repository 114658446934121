import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/Homepage.vue'
import Complaint from '../views/complaints/CreateComplaint.vue'
import CheckComplaint from '../views/complaints/UpdateComplaint.vue'
import NotFound from '../views/NotFound.vue'
import Layout from '../views/Layout.vue'
import Success from '../views/Success.vue'


Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      redirect: '/pt'
    },
    {
      path: '/:tenant',
      redirect: ':tenant/pt'
    },
    {
        path: '/:tenant/:lang',
        name: 'Home',
        component: Layout,
        children:[
            {
                path: '/:tenant/:lang',
                name: 'Home',
                component: Homepage
            },
            {
                path: '/:tenant/:lang/complaint',
                component: Complaint
            },
            {
                path: '/:tenant/:lang/complaint/success',
                component: Success
            },
            {
                path: '/:tenant/:lang/complaint/:password',
                component: CheckComplaint
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
