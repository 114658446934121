import BaseApi from "./BaseApi";

export default class Branding extends BaseApi{
    construct() {}

    static async get(params) {
        return await Branding.getAxiosInstance().get(Branding.getFullApiUrl() + 'no-auth',params);
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/client/branding/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/branding/";
    }
}