<template>
    <v-snackbar
        v-bind="$attrs"
        v-on="$listeners"
        top
        right
        :timeout="3500"
        color="error"
    >
        {{message || "Error!"}}

        <template v-slot:action="{attrs}">
            <v-btn
                fab
                text
                class="mb-2"
                v-bind="attrs"
                @click="close"
                :color="$root.$vuetify.theme.isDark ? 'primary' : 'white'"
                small
            >
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        message: String
    },
    methods: {
        close: function() {
            this.$emit("input", false)
        }
    }
}
</script>