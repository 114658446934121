import BaseApi from "./BaseApi";

export default class TenantCompany extends BaseApi{
    construct() {}

    static async getLanguage(params) {
        let url = TenantCompany.getFullApiUrl();

        return TenantCompany.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/ignoto-frontend/tenant-company/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/tenant-company/";
    }
}