import BaseApi from "./BaseApi";

export default class Complaints extends BaseApi{
    construct() {}

    static async getIntroduction(tenant) {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl(tenant) + 'introduction');
    }

    static async getThankYou(tenant) {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl(tenant) + 'thank-you');
    }

    static async create(params) {
        return await Complaints.getAxiosInstance().post(Complaints.getFullApiUrl(),params);
    }

    static async find(password) {
        return await Complaints.getAxiosInstance().get(Complaints.getFullApiUrl() + password);
    }

    static async downloadFile(file, id) {
        let url = Complaints.getFullApiUrl() + 'download/' + file + '/' + id
        
        return await Complaints.getAxiosInstanceDownload(url)
    }

    static getFullApiUrl(tenant = null){
        let domain = location.pathname.split('/')[1]
        
        if(tenant != null) domain = tenant;

        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/ignoto-frontend/complaints/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/complaints/";
    }
}