<template>
    <v-container fluid>
        <v-card v-if="complaint != null">
            <v-card-title class="primary white--text">
                Detalhes do Caso
            </v-card-title>
            <v-card-text class="mt-4">
                <v-row>
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.data')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.created_at}}
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.title')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.title}}
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.description')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        <span v-if="complaint.description != 'null'">{{complaint.description}}</span>
                        <div v-else>
                            <v-btn fab x-small :class="isPlaying(complaint) ? 'error white--text' : 'purple white--text'" @click="togglePlay(complaint)">
                                <v-icon v-if="isPlaying(complaint)">mdi-stop</v-icon>
                                <v-icon v-else>mdi-play</v-icon>
                            </v-btn>
                        </div>

                        <!-- Elemento de áudio escondido, controlado pelos botões -->
                        <audio ref="audioPlayer" :src="getSrc(complaint)" @ended="audioEnded"></audio>
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.category')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.category.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.state')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.state == null ? '-' : complaint.state.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold">
                        {{$t('globals.department')}}
                    </v-col>
                    <v-col cols="12" md="8">
                        {{complaint.department == null ? '-' : complaint.department.i18n[$i18n.locale]}}
                    </v-col>
                    
                    <v-col cols="12" md="4" class="font-weight-bold" v-if="false">
                        {{$t('globals.priority')}}
                    </v-col>
                    <v-col cols="12" md="8" v-if="false">
                        {{complaint.severity == null ? '-' : complaint.severity.level}}
                    </v-col>
                </v-row>

                <v-row v-for="item in complaint.files" :key="item">
                    <v-col cols="12" md="4">
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-btn @click="downloadFile(item)" icon class="success">
                            <v-icon color="white">mdi-download-box</v-icon>
                        </v-btn>
                        {{item}}
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
            <v-row v-for="comment in complaint.comments" :key="comment.id">
                <v-col cols="12" md="1">
                    <v-avatar
                        v-if="comment.user == null"
                        class="orange darken-2 white--text text-h6"
                        size="56"
                    >
                        Yours
                    </v-avatar>
                    <v-avatar
                        v-else
                        class="primary white--text text-h6"
                        size="56"
                    >
                        {{comment.user.initials}}
                    </v-avatar>
                </v-col>
                <v-col cols="12" md="11" class="mt-4">
                    {{comment.message}}
                </v-col>   
                <v-col cols="12" style="position:relative;" class="mt-n4">                    
                    <span class="text-caption" style="bottom: 0; right: 0; position: absolute;">
                        {{comment.user == null ? (complaint.anonymous ? 'Anonymous' : complaint.person.name) : comment.user.name}} at {{comment.created_at}}
                    </span>
                </v-col>
                <v-col cols="12" md="12" v-if="comment.files.length > 0">
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="primary white--text">
                                <template v-slot:actions>
                                    <v-icon class="icon" color="white">$expand</v-icon>
                                </template>
                                {{$t('globals.documents')}} ({{comment.files.length}})
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col cols="12" md="12" v-for="f in comment.files" :key="f">
                                    <v-btn @click="downloadCommentFile(f, comment.id)" icon class="success mr-2">
                                        <v-icon color="white">mdi-download-box</v-icon>
                                    </v-btn>
                                    <span class="text-caption">{{f}}</span>
                                </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>      
                <v-col cols="12">
                    
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row v-if="complaint.state.i18n['pt'] != 'Fechado'">
                <v-col cols="12">
                    <v-textarea 
                        filled
                        :label="$t('globals.insert_comment')"
                        v-model="comment.message"
                    />
                </v-col>
                <v-col cols="12" md="10">
                    <v-file-input
                        dense outlined
                        multiple
                        v-model="comment.files"
                        :label="$t('globals.attach')"
                    ></v-file-input>
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn block class="success" @click="addComment">{{$t('globals.comment')}}</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        </v-card>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            {{$t('globals.comment_success')}}
        </DialogSuccess>
    </v-container>
</template>
<script>
import Complaints from '@/api/Complaints.js'
import Comment from '@/api/Comment.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue'

export default{
    props:{
        complaint: Object
    },
    components:{
        DialogSuccess
    },
    mounted(){
    },
    data(){
        return{
            success: false,
            comment:{
                message: null,
                files: null,
                user_id: null,
                complaint_id: null
            },
            playingAudio: null, // Para armazenar o áudio que está sendo reproduzido
        }
    },
    methods:{
        getSrc(item){
            return process.env.VUE_APP_API_URL + this.$route.params.tenant + '/3Jk9lRf2Qb8wTg5Yh1pEwA3uZ/N7xXb2QpA4k8Yw1Ff2jLqV5mZ/T8n6Hc4RjQ1eWq9Fk5ZpJ8yXh/' + item.id
        },
        togglePlay(item) {
            const audio = this.$refs.audioPlayer; // Obtém o elemento de áudio
            if (this.playingAudio === item.id) {
            audio.pause();
            this.playingAudio = null; // Define que não há áudio tocando
            } else {
            if (this.playingAudio) {
                // Para o áudio que estava tocando
                const previousAudio = this.$refs.audioPlayer;
                previousAudio.pause();
            }
            audio.play();
            this.playingAudio = item.id; // Define qual áudio está tocando
            }
        },
        audioEnded() {
            this.playingAudio = null; // Reseta o estado quando o áudio termina
        },
        isPlaying(item) {
            return this.playingAudio === item.id; // Verifica se o áudio atual está tocando
        },
        onSuccess(){
            this.success = false
            this.$emit('reload')
        },
        addComment(){
            this.comment.complaint_id = this.complaint.id

            const formData = new FormData();
            formData.append('message', this.comment.message)
            formData.append('complaint_id', this.comment.complaint_id)
            formData.append('from_whistleblower', true)
            if(this.comment.files != null){
                for (var i = 0; i < this.comment.files.length; i++ ){
                    let file = this.comment.files[i];
                    formData.append('files[]', file);
                }
            }

            Comment['create'](formData).then(() => {
                this.success = true
                this.loading = false
            }).catch(err => {
                    this.loading = false;
        
                    if(err.response.status == 422) {
        
                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                    }
        
                    this.error.title = "Erro " + err.response.status;
        
                    this.error.message = err.response.data.message;
        
            });
        },        
        downloadCommentFile(file, id){
            Comment.downloadFile(file, id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        downloadFile(file){
            Complaints.downloadFile(file, this.complaint.id).then((response) => {
                let documentName =  file;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", documentName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = this.$t('globals.error') + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
    }
}
</script>