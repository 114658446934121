import BaseApi from "./BaseApi";

export default class Comment extends BaseApi{
    construct() {}

    static async create(params) {
        return await Comment.getAxiosInstance().post(Comment.getFullApiUrl(),params);
    }   

    static async downloadFile(file, id) {
        let url = Comment.getFullApiUrl() + 'download/' + file + '/' + id
        
        return await Comment.getAxiosInstanceDownload(url)
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/ignoto-frontend/comment/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/comment/";
    }
}