import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      themes: {
        light: {
          primary: '#0D1F2B',
          secondary: '#1A454A',
          accent: '#052947',
          error: '#b71c1c',
          info: '#4C7489',
          warning: '#E98459',
          achor: '#152B35',
          tertiary: '#074D56'
        },
      },
    },
});
