import BaseApi from "./BaseApi";

export default class User extends BaseApi{
    construct() {}

    static async list(params) {
        let url = User.getFullApiUrl() + "complaint_admin";

        return User.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(){
        let domain = location.pathname.split('/')[1]
        return BaseApi.getFullDomainApiUrl() + "api/" + domain + "/ignoto-frontend/users/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/client/users/";
    }
}