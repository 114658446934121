<template>
    <v-container fluid class="fill-height ma-0 pa-0 home-bg">
        <v-row justify="center" v-if="!loading">
            <v-col cols="12" md="8">
                <v-card style="display: block; margin: auto; background-color: rgba(255, 255, 255, 0.75) !important;">
                    <v-card-title class="ma-0 pa-0">
                        <v-card style="background-color: #3EC59D;" class="ma-0 pa-0" height="70">
                            <v-container fluid class="fill-height">
                                <v-row align="center" justify="center" no-gutters>
                                    <v-col cols="12">
                                        <v-icon size="x-large" color="white">mdi-check-circle-outline</v-icon>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-card-title>
                    <v-card-title class="text-h3 mt-8">
                        {{msg.i18n[$i18n.locale].title}}
                    </v-card-title>
                    <v-card-text class="text-h6 mt-8">
                        {{msg.i18n[$i18n.locale].description}}    
                    </v-card-text>
                    <v-card-text><v-btn class="primary" @click="$router.push('/' + $route.params.tenant + '/' + $i18n.locale)" dark>Voltar</v-btn></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Complaints from "@/api/Complaints.js"

export default{
    name: 'SuccessView',
    data(){
        return{
            loading: true,
            msg: null
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            Complaints.getThankYou().then((resp)    =>  {
                console.log(resp)
                this.msg = resp.data

                this.loading = false
            })
        }
    }
}
</script>